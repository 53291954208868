import { GetStatusFinished } from '@core/api/Order'
import { DateTimeFormatter, Instant, LocalDateTime } from '@js-joda/core'
import { mdiCart, mdiCurrencyUsd } from '@mdi/js'

import { ref, watch } from '@vue/composition-api'

export default function useOrderList2() {
  // Table Handlers
  const tableColumns = [
    { text: 'id', value: 'Id' },
    { text: 'Customer Name', value: 'Name' },
    { text: 'Phone', value: 'PhoneNumber'},
    { text: 'Creation Time', value: 'CreationTime' },
    { text: 'Amount', value: 'TotalAmount' },
    { text: 'Status', value: 'Status', sortable: false },
    { text: 'Action', value: 'Action', sortable: false },
  ]

  const OrderListTable = ref([])
  const OrderListTablef = ref([])
  const expanded = ref([])
  const searchQuery = ref('')
  const searchDateFrom = ref(new Date())
  const searchDateTo = ref(new Date())
  const statusFilter = ref(null)
  const orderSourceFilter = ref(null)
  const totalOrderListTable = ref(0)
  const totalOrderListTablef = ref(0)
  const OrderTotalLocal = ref([])

  searchDateFrom.value.setHours(0, 0, 0, 0)
  searchDateTo.value.setHours(23, 59, 59, 999)
  const options = ref({
    sortBy: ['id'],
    sortDesc: [false],
  })
  const loading = ref(false)
  let total = 0
  let canceltotal = ref(0)
  const CallPrice = item => {
    total +=
        ((item.OrderTransations.reduce((prev, cur) => {
          return prev + parseFloat(cur.TotalPrice) * cur.Qty
        }, 0) +
        item.PromoCodeAmount)-
      (item.OrderTransations.reduce((prev, cur) => {
          return prev + parseFloat(cur.TotalPrice) * cur.Qty
        }, 0) *
        item.PromoCodePercentage))
     }
  const fetchFinishedOrders = () => {
    const BranshId = JSON.parse(localStorage.getItem('userData')).BranshId
    GetStatusFinished({
      BranchId: BranshId,
      Any: searchQuery.value,
      OrderSource: orderSourceFilter.value,
      Status: statusFilter.value,

      DateFrom: LocalDateTime.ofInstant(Instant.ofEpochMilli(searchDateFrom.value)).format(
        DateTimeFormatter.ofPattern('yyyy-MM-dd HH:mm'),
      ),
      DateTo: LocalDateTime.ofInstant(Instant.ofEpochMilli(searchDateTo.value)).format(
        DateTimeFormatter.ofPattern('yyyy-MM-dd HH:mm'),
      ),
    })
      .then(response => {
        total = 0
        canceltotal.value = 0
        response.Items.forEach((i, index) => {
          if ( i.Status != 'cancelled'){
          CallPrice(i)
        }
        else {
          canceltotal.value = canceltotal.value + 1
        }
          i.OrderTransations.forEach((item, index) => {
             if(item.Addon != ''){
              item.Addon = JSON.parse(item.Addon)
              item.Addon2 = item.Addon
             }
            })
        })
        // handle success
        OrderListTablef.value = response.Items
        totalOrderListTablef.value = response.length
        response.Totals.push({'title':'Total Amount','total':total})
        OrderTotalLocal.value = response.Totals.reverse()
        // remove loading state
        loading.value = false
      })
      .catch(error => {
        loading.value = false
        console.log(error)
      })
  }

  watch([searchQuery, statusFilter, options, searchDateFrom, searchDateTo, orderSourceFilter,], () => {
    loading.value = true
    fetchFinishedOrders()
  })
  const resolveTotalIcon = total => {
    if (total === 'Count') return { icon: mdiCart, color: 'primary' }
    if (total === 'Total Amount') return { icon: mdiCurrencyUsd, color: 'success' }

    return { icon: mdiCart, color: 'primary' }
  }

  return {
    canceltotal,
    orderSourceFilter,
    total,
    CallPrice,
    fetchFinishedOrders,
    expanded,
    tableColumns,
    searchQuery,
    options,
    OrderListTable,
    OrderListTablef,
    statusFilter,
    totalOrderListTable,
    totalOrderListTablef,
    loading,
    searchDateFrom,
    searchDateTo,
    OrderTotalLocal,
    resolveTotalIcon,
  }
}
